exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-math-algebra-one-js": () => import("./../../../src/pages/courses/math/algebra-one.js" /* webpackChunkName: "component---src-pages-courses-math-algebra-one-js" */),
  "component---src-pages-courses-math-algebra-two-js": () => import("./../../../src/pages/courses/math/algebra-two.js" /* webpackChunkName: "component---src-pages-courses-math-algebra-two-js" */),
  "component---src-pages-courses-math-calculus-js": () => import("./../../../src/pages/courses/math/calculus.js" /* webpackChunkName: "component---src-pages-courses-math-calculus-js" */),
  "component---src-pages-courses-math-elementary-math-js": () => import("./../../../src/pages/courses/math/elementary-math.js" /* webpackChunkName: "component---src-pages-courses-math-elementary-math-js" */),
  "component---src-pages-courses-math-geometry-js": () => import("./../../../src/pages/courses/math/geometry.js" /* webpackChunkName: "component---src-pages-courses-math-geometry-js" */),
  "component---src-pages-courses-math-pre-algebra-js": () => import("./../../../src/pages/courses/math/pre-algebra.js" /* webpackChunkName: "component---src-pages-courses-math-pre-algebra-js" */),
  "component---src-pages-courses-math-precalculus-js": () => import("./../../../src/pages/courses/math/precalculus.js" /* webpackChunkName: "component---src-pages-courses-math-precalculus-js" */),
  "component---src-pages-courses-programming-javascript-js": () => import("./../../../src/pages/courses/programming/javascript.js" /* webpackChunkName: "component---src-pages-courses-programming-javascript-js" */),
  "component---src-pages-courses-programming-machine-learning-js": () => import("./../../../src/pages/courses/programming/machine-learning.js" /* webpackChunkName: "component---src-pages-courses-programming-machine-learning-js" */),
  "component---src-pages-courses-programming-python-js": () => import("./../../../src/pages/courses/programming/python.js" /* webpackChunkName: "component---src-pages-courses-programming-python-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

